import React, { useEffect, useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Divider,
  Alert,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
} from "@mui/material";
import { Email, Message, Phone, WhatsApp } from "@mui/icons-material";

function SwapDashboard() {
  const [activeTab, setActiveTab] = useState("County Match");
  const [swapsData, setSwapsData] = useState({
    alternative: [],
    ward: [],
    subcounty: [],
    county: [],
  });
  const [matchCounts, setMatchCounts] = useState({
    alternative: 0,
    ward: 0,
    subcounty: 0,
    county: 0,
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedSwap, setSelectedSwap] = useState(null); // State to hold the selected swap for payment
  const [isLoading, setIsLoading] = useState(false);
  const [mpesaText, setMpesaText] = useState("");
  const [isError, setIsError] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const paymentAmounts = {
    "Alternative Match": 2500,
    "Ward Match": 2000,
    "SubCounty Match": 1500,
    "County Match": 1000,
  };

  useEffect(() => {
    const fetchSwapsCounts = async () => {
      setIsLoading(true);
      try {
        const res = await fetch("/api/swaps/mystats", {
          credentials: "include",
        });
        setIsLoading(false);
        if (res.ok) {
          const data = await res.json();
          setMatchCounts(data);
        } else if (res.status === 401) {
          window.location.href = "/";
          throw new Error("Unauthorized access. Please log in.");
        } else if (res.status === 403) {
          window.location.href = "/";
          throw new Error(
            "Forbidden access. You do not have permission to view this resource."
          );
        } else {
          throw new Error("An error occurred while fetching data.");
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchSwapsCounts();
  }, []);

  useEffect(() => {
    const fetchSwapsByType = async () => {
      const typeMap = {
        "Alternative Match": "alternative",
        "Ward Match": "ward",
        "SubCounty Match": "subcounty",
        "County Match": "county",
      };

      const type = typeMap[activeTab];

      try {
        const res = await fetch(
          `/api/swaps/myswapsdata/${type}?page=${page}&limit=10`,
          {
            credentials: "include",
          }
        );

        if (res.ok) {
          const data = await res.json();
          setSwapsData((prevData) => ({
            ...prevData,
            [type]: data.matches,
          }));
          setTotalPages(data.totalPages);
        } else if (res.status === 401) {
          window.location.href = "/";
          throw new Error("Unauthorized access. Please log in.");
        } else if (res.status === 403) {
          window.location.href = "/";
          throw new Error(
            "Forbidden access. You do not have permission to view this resource."
          );
        } else {
          throw new Error("An error occurred while fetching data.");
        }
      } catch (error) {
        console.error("Error fetching swap matches:", error);
      }
    };

    fetchSwapsByType();
  }, [activeTab, page]);



  const renderTable = (data) =>
    !isMobile ? (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SN</TableCell>
              <TableCell>Teacher</TableCell>
              <TableCell>Other Info</TableCell>
              <TableCell>From Location</TableCell>
              <TableCell>To Location</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Payment Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((swap, index) => {
              return (
                <SwapItem
                  key={index}
                  index={index}
                  page={page}
                  swap={swap}
                  isMobile={isMobile}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      data.map((swap, index) => {
        return (
          <SwapItem
            key={index}
            index={index}
            page={page}
            swap={swap}
            isMobile={isMobile}
          />
        );
      })
    );

  return (
    <Box sx={{ marginTop: isMobile ? "5rem" : "5rem" }}>
      <ButtonGroup
        variant="contained"
        orientation={isMobile ? "vertical" : "horizontal"}
        fullWidth={isMobile}
        sx={{ boxShadow: "none" }}
      >
        <Button
          sx={{
            textTransform: "capitalize",
          }}
          onClick={() => setActiveTab("County Match")}
          startIcon={
            <Chip
              color={matchCounts.countyMatches > 0 ? "success" : "warning"}
              label={matchCounts.countyMatches}
            />
          }
          variant={activeTab === "County Match" ? "contained" : "outlined"}
        >
          County Match
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          onClick={() => setActiveTab("SubCounty Match")}
          startIcon={
            <Chip
              color={matchCounts.subCountyMatches > 0 ? "success" : "warning"}
              label={matchCounts.subCountyMatches}
            />
          }
          variant={activeTab === "SubCounty Match" ? "contained" : "outlined"}
        >
          SubCounty Match
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          onClick={() => setActiveTab("Ward Match")}
          startIcon={
            <Chip
              color={matchCounts.wardMatches > 0 ? "success" : "warning"}
              label={matchCounts.wardMatches}
            />
          }
          variant={activeTab === "Ward Match" ? "contained" : "outlined"}
        >
          Ward Match
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          onClick={() => setActiveTab("Alternative Match")}
          startIcon={
            <Chip
              color={matchCounts.alternativeMatches > 0 ? "success" : "warning"}
              label={matchCounts.alternativeMatches}
            />
          }
          variant={activeTab === "Alternative Match" ? "contained" : "outlined"}
        >
          Alternative Match
        </Button>
      </ButtonGroup>

      <Box marginTop={2} minHeight="50vh">
        {activeTab === "Alternative Match" &&
          renderTable(swapsData.alternative)}
        {activeTab === "Ward Match" && renderTable(swapsData.ward)}
        {activeTab === "SubCounty Match" && renderTable(swapsData.subcounty)}
        {activeTab === "County Match" && renderTable(swapsData.county)}
        {isLoading && (
          <Box display="flex" height="100%" width="100%" alignItems="center">
            <CircularProgress sx={{ display: "block", margin: "auto" }} />
          </Box>
        )}
      </Box>
      <Box marginTop={2} display="flex" justifyContent="center">
        <Button
          disabled={page <= 1}
          onClick={() => setPage((prevPage) => prevPage - 1)}
        >
          Previous
        </Button>
        <Box marginX={2}>
          Page {page} of {totalPages}
        </Box>
        <Button
          disabled={page >= totalPages}
          onClick={() => setPage((prevPage) => prevPage + 1)}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default SwapDashboard;

const SwapItem = (props) => {
  const { isMobile } = props;
  const [mePay, setMePay] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  useEffect(() => {
    console.log(props.swap);

    fetch(
      `/api/payments/getpayments/${props.swap.ID}/${props.swap.MatchedSwapID}`,
      { credentials: "include" }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          window.location.href = "/";
          throw new Error("Unauthorized access. Please log in.");
        } else if (res.status === 403) {
          window.location.href = "/";
          throw new Error(
            "Forbidden access. You do not have permission to view this resource."
          );
        } else {
          throw new Error("An error occurred while fetching data.");
        }
      })
      .then((data) => {
        if (data.MePay.length > 0) {
          setMePay(data.MePay[0]);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [props.swap]);

  const handleClick = () => {
    if (mePay && mePay?.Status === "Pending") {
      setOpenDialog(true);
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSubmit = () => {
    setLoading(true);
    fetch(`/api/payments/${mePay.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ Status: status }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setAlertMessage({
          type: "success",
          text: "Payment status updated successfully!",
        });
        setMePay(data); // Update the status locally
        setTimeout(() => {
          setOpenDialog(false);
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);
        setAlertMessage({
          type: "warning",
          text: "An error occurred. Please try again.",
        });
      });
  };

  const getButtonText = () => {
    if (!mePay) {
      return "No Action";
    } else if (mePay?.Status === "Pending") {
      return "Review Payment";
    } else {
      return "No Action"; // Default case
    }
  };

  if (isMobile) {
    return (
      <>
        {" "}
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between">
              <Chip label={props.index + (props.page - 1) * 10 + 1} />
              <Typography variant="body2">
                {props.swap.updatedAt.split("T")[0]}
              </Typography>
            </Box>

            <Typography variant="h6" sx={{ mt: 1 }}>
              {props.swap.Criteria}
            </Typography>
            <Divider my={2} />
            <Typography marginTop={1} variant="body1" gutterBottom>
              <strong>Name:</strong> {props.swap.Teacher.Name}
            </Typography>
            <DialogActions sx={{ marginTop: 0 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  window.open(
                    `tel:+254${props.swap.Teacher.User.Phone.slice(1)}`,
                    "_self"
                  )
                }
              >
                <Phone />
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  window.open(
                    `sms:+254${props.swap.Teacher.User.Phone.slice(1)}`,
                    "_self"
                  )
                }
              >
                <Message />
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  window.open(
                    `https://wa.me/+254${props.swap.Teacher.User.Phone.slice(
                      1
                    )}`,
                    "_blank"
                  )
                }
              >
                <WhatsApp />
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() =>
                  window.open(
                    `mailto:${props.swap.Teacher.User.Email}`,
                    "_self"
                  )
                }
              >
                <Email />
              </Button>
            </DialogActions>
            <Divider my={2} />
            <Typography variant="body2" sx={{ mb: 1 }}>
              <strong>Teacher Details:</strong> {props.swap.Subjects} -{" "}
              {props.swap.Type}
            </Typography>

            <Typography variant="body2" sx={{ mb: 1 }}>
              <strong>From Location:</strong> {props.swap.CountyFrom},{" "}
              {props.swap.SubCountyFrom}, {props.swap.WardFrom},{" "}
              {props.swap.SchoolFrom}
            </Typography>

            <Typography variant="body2" sx={{ mb: 1 }}>
              <strong>To Location:</strong> {props.swap.CountyTo},{" "}
              {props.swap.SubCountyTo}, {props.swap.WardTo},{" "}
              {props.swap.SchoolTo}
            </Typography>

            <Typography variant="body2" sx={{ mb: 2 }}>
              <strong>Status:</strong> {props.swap.Status}
            </Typography>

            <Box display="flex" flexDirection="column">
              <Chip
                sx={{ fontSize: "x-small", mb: 1 }}
                label={
                  mePay
                    ? mePay.Status === "Pending"
                      ? "Pending Confirmation"
                      : mePay.Status
                    : "Not Paid"
                }
                color={
                  mePay
                    ? mePay.Status === "Confirmed"
                      ? "success"
                      : "warning"
                    : "error"
                }
              />
            </Box>

            <Button
              variant="contained"
              onClick={handleClick}
              disabled={loading || !mePay || mePay.Status !== "Pending"}
              fullWidth
            >
              {loading ? <CircularProgress size={24} /> : getButtonText()}
            </Button>
          </CardContent>
        </Card>
        <Dialog
          sx={{ minWidth: "300px" }}
          open={openDialog}
          onClose={() => setOpenDialog(false)}
        >
          <DialogTitle color="primary">Review Payment</DialogTitle>
          <Divider />
          <DialogContent>
            <Typography
              sx={{ backgroundColor: "#f5f5f5", p: 1, borderRadius: "5px" }}
              gutterBottom
            >
              {mePay && mePay.MpesaCode}
            </Typography>
            <Select
              value={status}
              onChange={handleStatusChange}
              fullWidth
              gutterBottom
            >
              <MenuItem value="Confirmed">Confirmed</MenuItem>
              <MenuItem value="Payment Not Received">
                Payment Not Received
              </MenuItem>
              <MenuItem value="Amount Not Sufficient">
                Amount Not Sufficient
              </MenuItem>
              <MenuItem value="System Delay">System Delay</MenuItem>
            </Select>
            {alertMessage && (
              <Alert
                severity={alertMessage.type}
                onClose={() => setAlertMessage(null)}
              >
                {alertMessage.text}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleClick}
              disabled={loading || !mePay || mePay.Status !== "Pending"}
            >
              {getButtonText()}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <Chip label={props.index + (props.page - 1) * 10 + 1}></Chip>
        </TableCell>

        <TableCell>
          <Typography variant="body2"> {props.swap.Teacher.Name}</Typography>
          <Typography variant="body2">
            {" "}
            {props.swap.Teacher.User.Phone}
          </Typography>

          <Typography variant="body2">
            {" "}
            {props.swap.Teacher.User.Email}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2"> {props.swap.Subjects}</Typography>
          <Typography variant="body2"> {props.swap.Type}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {props.swap.CountyFrom}, {props.swap.SubCountyFrom},{" "}
            {props.swap.WardFrom}, {props.swap.SchoolFrom}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {props.swap.CountyTo}, {props.swap.SubCountyTo}, {props.swap.WardTo}
            , {props.swap.SchoolTo}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {props.swap.updatedAt.split("T")[0]}
          </Typography>
        </TableCell>
        <TableCell>
          <Chip
            sx={{ display: "flex", marginBottom: "5px" }}
            label={
              mePay
                ? mePay.Status === "Pending"
                  ? "Pending Confirmation"
                  : mePay.Status
                : "Not Paid"
            }
            color={
              mePay
                ? mePay.Status === "Confirmed"
                  ? "success"
                  : "warning"
                : "error"
            }
          />
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            onClick={handleClick}
            disabled={loading || !mePay || mePay.Status !== "Pending"}
          >
            {getButtonText()}
          </Button>
        </TableCell>
      </TableRow>

      <Dialog
        sx={{ minWidth: "300px" }}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle color="primary">Review Payment</DialogTitle>
        <Divider />
        <DialogContent>
          <Typography
            sx={{ backgroundColor: "#f5f5f5", p: 1, borderRadius: "5px" }}
            gutterBottom
          >
            {mePay && mePay.MpesaCode}
          </Typography>
          <Select
            value={status}
            onChange={handleStatusChange}
            fullWidth
            gutterBottom
          >
            <MenuItem value="Confirmed">Confirmed</MenuItem>
            <MenuItem value="Payment Not Received">
              Payment Not Received
            </MenuItem>
            <MenuItem value="Amount Not Sufficient">
              Amount Not Sufficient
            </MenuItem>
            <MenuItem value="System Delay">System Delay</MenuItem>
          </Select>
          {alertMessage && (
            <Alert
              severity={alertMessage.type}
              onClose={() => setAlertMessage(null)}
            >
              {alertMessage.text}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
