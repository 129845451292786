import React from "react";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import Navbar from "./Navbar";
import Home from "./pages/Home";
import Users from "./pages/Users";
import Settings from "./pages/Settings";
import Performance from "./pages/Performance";
import Messages from "./pages/Messages";
import Enquiries from "./pages/Enquiries";
import NewsLetter from "./pages/Teachers";
import BlogList from "./pages/Swaps";
import CreateBlog from "./pages/CreateBlog";
import Teachers from "./pages/Teachers";
import Billing from "./pages/Billing";

function Dashboard() {
  const [user, setUser] = React.useState(null);
  return (
    <Box sx={{ display: "flex" }}>
      <Navbar user={user} setUser={setUser} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Routes>
          <Route path="home" element={<Home />} />
          <Route path="payments" element={<Billing />} />
          <Route path="swaps" element={<BlogList />} />
          <Route path="messages" element={<Messages />} />
          <Route path="enquiries" element={<Enquiries />} />
          <Route path="teachers" element={<Teachers />} />
          {user && user.Role == "Admin" && (
            <Route path="users" element={<Users />} />
          )}
          <Route path="performance" element={<Performance />} />
          <Route path="settings" element={<Settings />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default Dashboard;
