import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  CircularProgress,
  Chip,
  Divider,
} from "@mui/material";
import dayjs from "dayjs";
import ReviewPaymentDialog from "./ReviewPaymentDialog"; // Import the component

export default function PaymentDetailsDialog({
  open,
  onClose,
  payment,
  paymentAmounts,
}) {
  const [swapDetails, setSwapDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [enquiryOpen, setEnquiryOpen] = useState(false);
  const [selectedSwap, setSelectedSwap] = useState(null);

  useEffect(() => {
    if (payment?.SwapID) {
      fetchSwapDetails(payment.SwapID);
    }
  }, [payment]);

  const fetchSwapDetails = async (swapID) => {
    setLoading(true);
    try {
      const response = await fetch(`/api/swaps/${swapID}`);
      if (response.ok) {
        const data = await response.json();
        setSwapDetails(data);
      } else {
        console.error("Failed to fetch swap details");
      }
    } catch (error) {
      console.error("An error occurred while fetching swap details", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEnquiryOpen = () => {
    setSelectedSwap(swapDetails); // Set selected swap for the review dialog
    setEnquiryOpen(true);
  };

  const handleEnquiryClose = () => {
    setEnquiryOpen(false);
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        sx={{
          maxWidth: { xs: "100vw", md: "50vw" },
          width: "100%",
          margin: "auto",
        }}
        onClose={onClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Payment Details</DialogTitle>
        <Divider />
        <DialogContent>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box mb={2}>
                <Typography
                  sx={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 5,
                    overflow: "hidden",
                  }}
                  variant="body1"
                >
                  <strong>Mpesa Code:</strong> {payment.MpesaCode}
                </Typography>
                <Typography variant="body1">
                  <strong>Amount:</strong> {payment.Amount}
                </Typography>
                <Typography variant="body1">
                  <strong>Date:</strong>{" "}
                  {dayjs(payment.PaymentDate).format("DD/MM/YYYY")}
                </Typography>
                <Typography variant="body1">
                  <strong>Status:</strong>
                  <Chip
                    label={payment.Status}
                    color={
                      payment.Status === "Confirmed" ? "success" : "warning"
                    }
                    sx={{ ml: 1 }}
                  />
                </Typography>
              </Box>

              {swapDetails && (
                <>
                  <Typography variant="h6">Swap Details</Typography>
                  <Divider sx={{ my: 1 }} />
                  <Box>
                    <Typography variant="body1">
                      <strong>From Location:</strong> {swapDetails.WardFrom},{" "}
                      {swapDetails.SubCountyFrom}, {swapDetails.CountyFrom}
                    </Typography>
                    <Typography variant="body1">
                      <strong>To Location:</strong> {swapDetails.WardTo},{" "}
                      {swapDetails.SubCountyTo}, {swapDetails.CountyTo}
                    </Typography>
                  </Box>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
          {payment.Status == "Pending" && (
            <Button
              onClick={handleEnquiryOpen}
              variant="contained"
              color="primary"
            >
              Review Payment
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Payment Review Dialog */}
      <ReviewPaymentDialog
        open={enquiryOpen}
        onClose={handleEnquiryClose}
        selectedSwap={selectedSwap}
        setSelectedSwap={setSelectedSwap}
        mePay={payment}
      />
    </>
  );
}
