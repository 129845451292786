import React, { useEffect, useState } from "react";
import { Box, Card } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TopItem } from "../home/TopItem";
import { BarChart } from "@mui/x-charts/BarChart";

function Home() {
  const [data, setData] = useState({
    alternative: 0,
    ward: 0,
    subcounty: 0,
    county: 0,
    requestsByCounty: [],
    requestsBySubcounty: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const swapsResponse = await fetch("/api/statistics/swaps", {
          credentials: "include",
        });
        const swapsData = await swapsResponse.json();

        const requestsByCountyResponse = await fetch(
          "/api/statistics/requests-by-county",
          {
            credentials: "include",
          }
        );
        const requestsByCountyData = await requestsByCountyResponse.json();

        const requestsBySubcountyResponse = await fetch(
          "/api/statistics/requests-by-subcounty",
          {
            credentials: "include",
          }
        );
        const requestsBySubcountyData =
          await requestsBySubcountyResponse.json();

    
        setData({
          alternative: swapsData?.alternative || 0,
          ward: swapsData?.ward || 0,
          subcounty: swapsData?.subcounty || 0,
          county: swapsData?.county || 0,
          requestsByCounty: Array.isArray(requestsByCountyData)
            ? requestsByCountyData
            : [],
          requestsBySubcounty: Array.isArray(requestsBySubcountyData)
            ? requestsBySubcountyData
            : [],
        });
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box marginTop={8} padding={1}>
      <Grid container spacing={3}>
        <Grid xs={12} sm={6} md={3}>
          <TopItem title="Alternative Match" value={data.alternative} trend="up" diff={0} />
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <TopItem title="Ward-Ward" value={data.ward} trend="up" diff={0} />
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <TopItem
            title="Subcounty-Subcounty"
            value={data.subcounty}
            trend="up"
            diff={0}
          />
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <TopItem
            title="County-County"
            value={data.county}
            trend="up"
            diff={0}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
          >
            <BarChart
              title="Requests by County"
              xAxis={[
                {
                  scaleType: "band",
                  data: data.requestsByCounty.map((item) => item.name),
                },
              ]}
              series={[
                { data: data.requestsByCounty.map((item) => item.count) },
              ]}
              height={300}
            />
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
          >
            <BarChart
              title="Requests by Subcounty"
              xAxis={[
                {
                  scaleType: "band",
                  data: data.requestsBySubcounty.map((item) => item.name),
                },
              ]}
              series={[
                { data: data.requestsBySubcounty.map((item) => item.count) },
              ]}
              height={300}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;
