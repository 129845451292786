import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";

export function DeleteDialog({ open, onClose, teacher }) {
  const handleDelete = () => {
    fetch(`/api/teachers/${teacher.UserID}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) throw Error("Failed to delete teacher");
        onClose();
      })
      .catch((err) => console.error(err));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Teacher</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {teacher?.Name}? This action cannot be
          undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleDelete} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function EditDialog({ open, onClose, teacher }) {
  const [formData, setFormData] = React.useState({});

  React.useEffect(() => {
    if (teacher) {
      setFormData({
        Name: teacher.Name,
        Type: teacher.Type,
        Subjects: teacher.Subjects,
        CountyTo: teacher.CountyTo,
        SubCountyTo: teacher.SubCountyTo,
        WardTo: teacher.WardTo,
        SchoolTo: teacher.SchoolTo,
      });
    }
  }, [teacher]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    fetch(`/api/teachers/profile/${teacher.UserID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (!res.ok) throw Error("Failed to update teacher");
        onClose();
      })
      .catch((err) => console.error(err));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Teacher</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          name="Name"
          fullWidth
          value={formData.Name || ""}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          label="Type"
          name="Type"
          fullWidth
          value={formData.Type || ""}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          label="Subjects"
          name="Subjects"
          fullWidth
          value={formData.Subjects || ""}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          label="Desired County"
          name="CountyTo"
          fullWidth
          value={formData.CountyTo || ""}
          onChange={handleChange}
          margin="normal"
        />
        {/* Add other fields for SubCountyTo, WardTo, SchoolTo as needed */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
