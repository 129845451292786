import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
  CircularProgress,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";

export default function ReviewPaymentDialog({
  open,
  onClose,
  mePay,
}) {
  const [status, setStatus] = useState(mePay?.Status || "");
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSubmit = () => {
    setLoading(true);
    fetch(`/api/payments/${mePay.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ Status: status }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setAlertMessage({
          type: "success",
          text: "Payment status updated successfully!",
        });
        setTimeout(() => {
          onClose(false);
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);
        setAlertMessage({
          type: "warning",
          text: "An error occurred. Please try again.",
        });
        console.error(error);
      });
  };

  return (
    <Dialog
      sx={{ minWidth: "300px" }}
      open={open}
      onClose={() => onClose(false)}
    >
      <DialogTitle color="primary">Review Payment</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography
          sx={{ backgroundColor: "#f5f5f5", p: 1, borderRadius: "5px" }}
          gutterBottom
        >
          {mePay && mePay.MpesaCode}
        </Typography>
        <Select
          value={status}
          onChange={handleStatusChange}
          fullWidth
          sx={{ mb: 2 }}
        >
          <MenuItem value="Confirmed">Confirmed</MenuItem>
          <MenuItem value="Payment Not Received">Payment Not Received</MenuItem>
          <MenuItem value="Amount Not Sufficient">
            Amount Not Sufficient
          </MenuItem>
          <MenuItem value="System Delay">System Delay</MenuItem>
        </Select>
        {alertMessage && (
          <Alert
            severity={alertMessage.type}
            onClose={() => setAlertMessage(null)}
          >
            {alertMessage.text}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
