import * as React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Eye as EyeIcon } from "@phosphor-icons/react";
import { EyeSlash as EyeSlashIcon } from "@phosphor-icons/react";
import { Controller, useForm } from "react-hook-form";
import { z as zod } from "zod";
import { Box, Card, CircularProgress } from "@mui/material";
import logo from "../assets/images/logo.svg";
import lg_img from "../assets/images/auth-widget.png";
import ForgotPassword from "./ForgotPassword";

const schema = zod.object({
  Email: zod.string().min(1, { message: "Email is required" }).email(),
  Password: zod.string().min(1, { message: "Password is required" }),
});

const defaultValues = { email: "", password: "" };

export default function LoginPage(props) {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [showing, setShowing] = React.useState(false);
  const [isPending, setIsPending] = React.useState(false);
  const [isError, setIsError] = React.useState("");

  const onSubmit = (data) => {
    setIsError("");
    setIsPending(true);

    fetch("/api/auth/login", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw new Error("Login failed!");
      })
      .then((data) => {
        setIsPending(false);
        if (data.success) {
          navigate("/admin/home");
        } else {
          setIsError(data.error);
        }
      })
      .catch((err) => {
        setIsPending(false);
        setIsError("Login failed!");
      });
  };

  return (
    <Box
      sx={{
        display: {
          xs: "flex",
          background:
            "radial-gradient(50% 50% at 50% 50%,#8ed1fc  0%, #0693e3 100%)",
          lg: "grid",
        },
        flexDirection: "column",
        gridTemplateColumns: "1fr 1fr",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          p: 2,
        }}
      >
        <Card
          sx={{
            display: "flex",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            backdropFilter: "blur(30px)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            borderRadius: 5,
            maxWidth: "450px",
            width: "100%",
            margin: "auto",
            p: 3,
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Stack spacing={2}>
              <Typography
                sx={{ textAlign: "center", color: "white", mt: 2 }}
                variant="h4"
              >
                Admin Login
              </Typography>
              <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                  <Controller
                    control={control}
                    name="Email"
                    render={({ field }) => (
                      <FormControl error={Boolean(errors.Email)} fullWidth>
                        <InputLabel
                          sx={{
                            color: "rgba(255, 255, 255, 0.7)", // Label color
                          }}
                        >
                          Email address
                        </InputLabel>
                        <OutlinedInput
                          {...field}
                          label="Email address"
                          type="email"
                          fullWidth
                          sx={{
                            backgroundColor: "rgba(255, 255, 255, 0.2)", // Transparent white background
                            color: "white", // Text color
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(255, 255, 255, 0.5)", // Outline color
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "white", // Outline color on hover
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "white", // Outline color when focused
                            },
                            "& input": {
                              color: "white", // Text color when typing
                            },
                          }}
                        />
                        {errors.Email ? (
                          <FormHelperText>
                            {errors.Email.message}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    )}
                  />
                  <Controller
                    control={control}
                    name="Password"
                    render={({ field }) => (
                      <FormControl error={Boolean(errors.Password)} fullWidth>
                        <InputLabel
                          sx={{
                            color: "rgba(255, 255, 255, 0.7)", // Label color
                          }}
                        >
                          Password
                        </InputLabel>
                        <OutlinedInput
                          {...field}
                          endAdornment={
                            showPassword ? (
                              <EyeIcon
                                cursor="pointer"
                                fontSize="var(--icon-fontSize-md)"
                                onClick={() => setShowPassword(false)}
                              />
                            ) : (
                              <EyeSlashIcon
                                cursor="pointer"
                                fontSize="var(--icon-fontSize-md)"
                                onClick={() => setShowPassword(true)}
                              />
                            )
                          }
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          fullWidth
                          sx={{
                            backgroundColor: "rgba(255, 255, 255, 0.2)", // Transparent white background
                            color: "white", // Text color
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(255, 255, 255, 0.5)", // Outline color
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "white", // Outline color on hover
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "white", // Outline color when focused
                            },
                            "& input": {
                              color: "white", // Text color when typing
                            },
                          }}
                        />
                        {errors.Password ? (
                          <FormHelperText>
                            {errors.Password.message}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    )}
                  />
                  <div>
                    <Link
                      onClick={() => {
                        setShowing(true);
                      }}
                      variant="subtitle2"
                      sx={{
                        textAlign: "center",
                        color: "white",
                        cursor: "pointer",
                      }}
                    >
                      Forgot password?
                    </Link>
                  </div>
                  {errors.root ? (
                    <Alert color="error">{errors.root.message}</Alert>
                  ) : null}
                  <Button
                    disabled={isPending}
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                  >
                    {isPending ? <CircularProgress size={24} /> : "Sign in"}
                  </Button>
                </Stack>
              </form>

              {isError && <Alert color="warning">{isError}</Alert>}
            </Stack>
          </Box>
        </Card>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          color: "var(--mui-palette-common-white)",
          display: { xs: "none", lg: "grid" },
          gridTemplateRows: "auto 1fr",
          justifyContent: "center",
          p: 3,
          minHeight: "100%",
        }}
      >
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Typography
              color="inherit"
              sx={{
                fontSize: "32px",
                color: "orange",
                lineHeight: "32px",
                textAlign: "center",
              }}
              variant="h1"
            >
              Welcome to <br></br>
              <Box component="span" sx={{ color: "white", lineHeight: "54px" }}>
                TSwapMate Admin Portal
              </Box>
            </Typography>
          </Stack>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="img"
              alt="Widgets"
              src={lg_img}
              sx={{ height: "auto", width: "100%", maxWidth: "500px" }}
            />
          </Box>
        </Stack>
        <ForgotPassword open={showing} showForgotPassword={setShowing} />
      </Box>
    </Box>
  );
}
