import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import LoginPage from "./Admin/LoginPage";
import Dashboard from "./Admin/Dashboard";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in ms
    });
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/admin/*" element={<Dashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
